import { Component } from 'solid-js';
import { ZoomIn, ZoomOut, RotateCw } from 'lucide-solid';

interface FeedbackControlsProps {
  scale: number;
  rotation: number;
  currentWidth: number;
  onZoom: () => void;
  onRotate: () => void;
  backgroundColor?: string;
  color?: string;
}

export const FeedbackControls: Component<FeedbackControlsProps> = (props) => {
  // Calculate true scale percentage based on 1024x7168 reference size
  const getRealScalePercentage = (scale: number) => {
    const baseWidth = 1024;
    const realScale = (scale * props.currentWidth) / baseWidth;
    return Math.round(realScale * 100);
  };

  // const handleTouchStart = (e: TouchEvent) => {
  //   e.stopPropagation(); // Stop event from bubbling to container
  // };

  return (
    <div
      class="absolute z-10 flex items-center gap-2 p-2 rounded-full shadow-lg backdrop-blur-sm transition-all duration-300"
      style={{
        background: props.backgroundColor ?? 'rgba(0, 0, 0, 0.7)',
        color: props.color ?? '#ffffff',
        right: '1rem',
        bottom: '1rem',
        transform: `scale(${props.scale > 1 ? 1.1 : 1})`,
      }}
      onTouchStart={(e) => e.stopPropagation()}
    >
      {/* Scale Indicator */}
      <span class="text-sm font-medium px-2">
        {getRealScalePercentage(props.scale)}%
      </span>

      <div class="w-px h-6 bg-white/20" />

      {/* Zoom Button */}
      <button
        onClick={() => props.onZoom()}
        onTouchStart={(e) => {
          e.stopPropagation();
          props.onZoom();
        }}
        class="p-2 rounded-full transition-all duration-300 hover:bg-white/10 touch-none"
        title={`Current zoom: ${getRealScalePercentage(props.scale)}%`}
      >
        {props.scale > 1 ? <ZoomOut size={24} /> : <ZoomIn size={24} />}
      </button>

      <div class="w-px h-6 bg-white/20" />

      {/* Rotation Button */}
      <button
        onClick={() => props.onRotate()}
        onTouchStart={(e) => {
          e.stopPropagation();
          props.onRotate();
        }}
        class="p-2 rounded-full transition-all duration-300 hover:bg-white/10 touch-none"
        title="Rotate image"
      >
        <div
          class="transform transition-all duration-500"
          style={{
            transform: `rotate(${props.rotation}deg)`,
          }}
        >
          <RotateCw size={24} />
        </div>
      </button>
    </div>
  );
};
