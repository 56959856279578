import { Logger } from '@repo/logger';

export class ZoomController {
  private readonly ZOOM_LEVELS = [0.25, 0.5, 0.75, 1];
  private readonly DEFAULT_ZOOM_INDEX = 3; // 1x zoom
  private currentIndex: number;
  private logger: Logger;

  constructor() {
    this.logger = new Logger('ZoomController');
    this.currentIndex = this.DEFAULT_ZOOM_INDEX;

    this.logger.debug('Initialized zoom controller', {
      levels: this.ZOOM_LEVELS,
      defaultIndex: this.DEFAULT_ZOOM_INDEX,
      defaultScale: this.ZOOM_LEVELS[this.DEFAULT_ZOOM_INDEX],
    });
  }

  get scale(): number {
    return this.ZOOM_LEVELS[this.currentIndex]!;
  }

  set scale(scale: number) {
    const index = this.ZOOM_LEVELS.indexOf(scale);
    if (index === -1) {
      this.logger.warn('Attempted to set invalid zoom scale', { scale });
      return;
    }

    this.logger.debug('Setting zoom scale', {
      previousIndex: this.currentIndex,
      newIndex: index,
      newScale: scale,
    });

    this.currentIndex = index;
  }

  cycleZoom(): number {
    const previousIndex = this.currentIndex;
    this.currentIndex = (this.currentIndex + 1) % this.ZOOM_LEVELS.length;
    const newScale = this.scale;

    this.logger.debug('Cycling zoom level', {
      previousIndex,
      newIndex: this.currentIndex,
      previousScale: this.ZOOM_LEVELS[previousIndex],
      newScale,
    });

    return newScale;
  }

  reset(): void {
    this.logger.debug('Resetting zoom', {
      previousIndex: this.currentIndex,
      resetIndex: this.DEFAULT_ZOOM_INDEX,
    });

    this.currentIndex = this.DEFAULT_ZOOM_INDEX;
  }
}
