import { createStore } from 'solid-js/store';
import { Position, Dimensions } from './types';

export interface TransformState {
  isMobile: boolean;
  scale: number;
  rotation: number;
  position: Position;
  isPortrait: boolean;
  dragging: boolean;
}

export function createTransformStore() {
  const [state, setState] = createStore<TransformState>({
    isMobile: false,
    scale: 1,
    rotation: 0,
    position: { x: 0, y: 0 },
    isPortrait: true,
    dragging: false,
  });

  const actions = {
    setIsMobile(isMobile: boolean) {
      setState('isMobile', isMobile);
    },

    setScale(scale: number) {
      setState('scale', Math.max(0.125, Math.min(2.5, scale)));
    },

    setRotation(rotation: number) {
      setState((store) => ({
        rotation: rotation,
        isPortrait: rotation % 180 === 0,
      }));
    },

    setPosition(position: Position) {
      setState('position', { ...position });
    },

    setDragging(dragging: boolean) {
      setState('dragging', dragging);
    },

    reset() {
      setState({
        scale: 1,
        rotation: 0,
        position: { x: 0, y: 0 },
        isPortrait: true,
        dragging: false,
      });
    },

    getTransformStyle() {
      return `
        translate(${state.position.x}px, ${state.position.y}px)
        rotate(${state.rotation}deg)
        scale(${state.scale})
      `.trim();
    },
  };

  return { state, actions };
}

export type TransformStore = ReturnType<typeof createTransformStore>;
