import { Logger } from '@repo/logger';
import { Dimensions, Position } from './types';

export class BoundsController {
  private readonly WINDOW_THRESHOLD = 0.9;
  private readonly SPRING_TENSION = 0.2;
  private dimensions: Dimensions = { width: 0, height: 0 };
  private containerRef: HTMLDivElement | undefined;
  private isMobile: boolean = false;
  private logger: Logger;

  constructor(
    dimensions: Dimensions,
    containerRef: HTMLDivElement | undefined,
    imageRef: HTMLImageElement | undefined,
  ) {
    this.logger = new Logger('BoundsController');
    this.dimensions = dimensions;
    this.containerRef = containerRef;

    this.logger.debug('Initialized with dimensions', {
      dimensions,
      hasContainer: !!containerRef,
      hasImage: !!imageRef,
    });
  }

  getDimensions(): Dimensions {
    return { ...this.dimensions };
  }

  updateDimensions(dimensions: Dimensions): void {
    this.logger.debug('Updating dimensions', {
      previous: this.dimensions,
      new: dimensions,
    });
    this.dimensions = { ...dimensions };
  }

  getContainer(): HTMLDivElement | undefined {
    return this.containerRef;
  }

  updateContainer(container: HTMLDivElement): void {
    this.logger.debug('Updating container reference', {
      previousContainer: !!this.containerRef,
      newContainer: !!container,
    });
    this.containerRef = container;
  }

  updateMobileState(value: boolean) {
    this.logger.debug('Updating mobile state', { isMobile: value });
    this.isMobile = value;
  }

  calculateBounds(props: {
    isMobile: boolean;
    position: Position;
    scale: number;
    isPortrait: boolean;
    containerSize: Dimensions;
    skipBounds?: boolean;
  }): Position {
    // For initial render, ensure the image is centered
    if (props.position.x === 0 && props.position.y === 0) {
      return { x: 0, y: 0 };
    }

    // Return position without bounds for touch events
    if (
      !this.containerRef ||
      props.skipBounds ||
      this.isMobile ||
      props.isMobile
    ) {
      this.logger.debug('Skipping bounds calculation');
      return props.position;
    }

    this.logger.debug('Calculating bounds', props);

    const scaledWidth = this.dimensions.width * props.scale;
    const scaledHeight = this.dimensions.height * props.scale;

    // During mouse drag, constrain based on orientation
    if (!props.isPortrait) {
      return {
        x: props.position.x, // Allow free movement on X axis
        y: this.constrainAxis(
          props.position.y,
          scaledHeight,
          props.containerSize.height,
        ),
      };
    } else {
      return {
        x: this.constrainAxis(
          props.position.x,
          scaledWidth,
          props.containerSize.width,
        ),
        y: props.position.y, // Allow free movement on Y axis
      };
    }
  }

  private constrainAxis(
    position: number,
    scaledSize: number,
    containerSize: number,
  ): number {
    const threshold = (containerSize * this.WINDOW_THRESHOLD) / 2;
    const maxDistance = scaledSize / 2 - threshold;

    if (maxDistance <= 0) {
      return 0;
    }

    return Math.max(Math.min(position, maxDistance), -maxDistance);
  }
}

// import { Logger } from '@repo/logger';
// import { Dimensions, Position } from './types';

// export class BoundsController {
//   private readonly WINDOW_THRESHOLD = 0.9;
//   private readonly SPRING_TENSION = 0.2;
//   private dimensions: Dimensions = { width: 0, height: 0 };
//   private containerRef: HTMLDivElement | undefined;
//   private logger: Logger;

//   constructor(
//     dimensions: Dimensions,
//     containerRef: HTMLDivElement | undefined,
//     imageRef: HTMLImageElement | undefined,
//   ) {
//     this.logger = new Logger('BoundsController');
//     this.dimensions = dimensions;
//     this.containerRef = containerRef;

//     this.logger.debug('Initialized with dimensions', {
//       dimensions,
//       hasContainer: !!containerRef,
//       hasImage: !!imageRef,
//     });
//   }

//   getDimensions(): Dimensions {
//     return { ...this.dimensions };
//   }

//   updateDimensions(dimensions: Dimensions): void {
//     this.logger.debug('Updating dimensions', {
//       previous: this.dimensions,
//       new: dimensions,
//     });
//     this.dimensions = { ...dimensions };
//   }

//   getContainer(): HTMLDivElement | undefined {
//     return this.containerRef;
//   }

//   updateContainer(container: HTMLDivElement): void {
//     this.logger.debug('Updating container reference', {
//       previousContainer: !!this.containerRef,
//       newContainer: !!container,
//     });
//     this.containerRef = container;
//   }

//   calculateBounds(props: {
//     position: Position;
//     scale: number;
//     isPortrait: boolean;
//     containerSize: Dimensions;
//     skipBounds?: boolean; // New parameter to skip bounds checking
//   }): Position {
//     if (!this.containerRef || props.skipBounds) {
//       return props.position;
//     }

//     this.logger.debug('Calculating bounds', props);

//     const scaledWidth = this.dimensions.width * props.scale;
//     const scaledHeight = this.dimensions.height * props.scale;

//     if (props.isPortrait) {
//       return this.calculatePortraitBounds(
//         props.position,
//         scaledHeight,
//         props.containerSize,
//       );
//     } else {
//       return this.calculateLandscapeBounds(
//         props.position,
//         scaledWidth,
//         props.containerSize,
//       );
//     }
//   }

//   private calculatePortraitBounds(
//     position: Position,
//     scaledHeight: number,
//     containerSize: Dimensions,
//   ): Position {
//     const windowThreshold = (containerSize.height * this.WINDOW_THRESHOLD) / 2;
//     const distToCenter = scaledHeight / 2;

//     // In portrait mode, only constrain X movement
//     if (distToCenter <= windowThreshold) {
//       // return { x: position.x, y: 0 };
//       return { x: 0, y: position.y };
//     }

//     const distRemaining = distToCenter - Math.abs(position.y);
//     if (distRemaining < windowThreshold) {
//       const desiredPosition = distToCenter - windowThreshold;
//       return {
//         x: desiredPosition * Math.sign(position.x),
//         y: position.y, // Keep Y position unchanged
//       };
//     }

//     return position;
//   }

//   private calculateLandscapeBounds(
//     position: Position,
//     scaledWidth: number,
//     containerSize: Dimensions,
//   ): Position {
//     const windowThreshold = (containerSize.width * this.WINDOW_THRESHOLD) / 2;
//     const distToCenter = scaledWidth / 2;

//     // In landscape mode, only constrain Y movement
//     if (distToCenter <= windowThreshold) {
//       // return { x: 0, y: position.y };
//       return { x: position.x, y: 0 };
//     }

//     const distRemaining = distToCenter - Math.abs(position.x);
//     if (distRemaining < windowThreshold) {
//       const desiredPosition = distToCenter - windowThreshold;
//       return {
//         x: position.x, // Keep X position unchanged
//         y: desiredPosition * Math.sign(position.y),
//       };
//     }

//     return position;
//   }
// }
