import { Logger } from '@repo/logger';
import { TransformState } from './TransformStore';
import { TouchGestureState } from './TouchGestureState';

export interface InteractionController {
  /** Handle start of interaction */
  handleStart: (e: MouseEvent | TouchEvent) => void;
  /** Handle movement */
  handleMove: (e: MouseEvent | TouchEvent) => void;
  /** Handle end of interaction */
  handleEnd: (e: MouseEvent | TouchEvent) => void;
  /** Handle control actions */
  handleControl: (action: 'zoom' | 'rotate') => void;
  /** Clean up resources */
  cleanup: () => void;
}

export interface TransformController {
  /** Get current transform state */
  getState: () => TransformState;
  /** Update transform state */
  setState: (updates: Partial<TransformState>) => void;
  /** Reset to initial state */
  reset: () => void;
  /** Get CSS transform string */
  getTransformStyle: () => string;
}

export interface LoaderConfig {
  /** Logger instance */
  logger: Logger;
  /** Load retry attempts */
  maxAttempts?: number;
  /** Timeout duration */
  timeout?: number;
  /** Success callback */
  onLoad?: () => void;
  /** Error callback */
  onError?: (error: Error) => void;
}

export interface Position {
  x: number;
  y: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface ViewportBounds {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface ControllerConfig {
  /** Zoom controller configuration */
  zoom: {
    levels: number[];
    defaultIndex: number;
  };
  /** Animation timing configuration */
  animation: {
    bounceDuration: number;
    easingFn: (t: number) => number;
  };
  /** Bounds checking configuration */
  bounds: {
    windowThreshold: number;
    springTension: number;
  };
  /** Touch gesture configuration */
  touch: {
    doubleTapThreshold: number;
    minRotationDelta: number;
    minScale: number;
    maxScale: number;
  };
}

// Event Handler Result Types
export interface BoundsCalculationResult {
  /** Bounded position */
  position: Position;
  /** Distance from threshold */
  tension: number;
  /** Axis of constraint */
  constraintAxis: 'x' | 'y';
}

export interface GestureCalculationResult {
  /** New scale value */
  scale: number;
  /** New position */
  position: Position;
  /** New rotation angle */
  rotation?: number;
  /** Center point of gesture */
  center?: Position;
}

// Controller State Types
export interface ImageState {
  /** Current dimensions */
  dimensions: Dimensions;
  /** Loading state */
  isLoading: boolean;
  /** Error state if any */
  error: Error | null;
  /** Reference to image element */
  element: HTMLImageElement | null;
}

export interface PreviewState {
  /** Transform state */
  transform: TransformState;
  /** Image state */
  image: ImageState;
  /** Current gesture state */
  gesture: TouchGestureState | null;
}

// Shared utilities for transform operations
export class TransformUtils {
  private static readonly ZOOM_LEVELS = [0.25, 0.5, 0.75, 1, 1.5, 2];
  private static readonly ROTATION_INCREMENTS = 90;

  static snapToNearestZoomLevel(scale: number): number {
    return this.ZOOM_LEVELS.reduce((prev, curr) =>
      Math.abs(curr - scale) < Math.abs(prev - scale) ? curr : prev,
    );
  }

  static snapToNearestRotation(rotation: number): number {
    const delta = rotation % this.ROTATION_INCREMENTS;
    let snapAngle =
      rotation + (delta <= this.ROTATION_INCREMENTS / 2 ? -delta : delta); // snap back to nearest 90 lesser degree angle
    return Math.round(snapAngle);
  }

  static getNextZoomLevel(currentScale: number): number {
    const snappedScale = this.snapToNearestZoomLevel(currentScale);
    const currentIndex = this.ZOOM_LEVELS.indexOf(snappedScale);
    const nextIndex = (currentIndex + 1) % this.ZOOM_LEVELS.length;
    return this.ZOOM_LEVELS[nextIndex] ?? 1;
  }

  static getNextRotation(currentRotation: number): number {
    const snappedRotation = this.snapToNearestRotation(currentRotation);
    return snappedRotation + this.ROTATION_INCREMENTS;
  }
}
