import { Logger } from '@repo/logger';
import { TransformStore } from './TransformStore';
import { BoundsController } from './BoundsController';
import { AnimationController } from './AnimationController';
import { Position } from './types';
import { TouchGestureState } from './TouchGestureState';
import { TransformUtils } from './types';
import { ControlsHandler } from './ControlsHandler';

export class MobileInteractionHandler {
  private logger: Logger;
  private static readonly DOUBLE_TAP_THRESHOLD = 300;
  private static readonly MIN_SCALE = 0.125;
  private static readonly MAX_SCALE = 2.5;
  private static readonly MIN_ROTATION_DELTA = 5;
  private static readonly REFERENCE_WIDTH = 1024;

  // private imagePreview: typeof ImagePreview;
  private gestureState: TouchGestureState | null = null;
  private isMobile: boolean = false;

  constructor(
    private transformStore: TransformStore,
    private boundsController: BoundsController,
    private animationController: AnimationController,
    private controlsHandler: ControlsHandler,
  ) {
    this.logger = new Logger('MobileInteractionHandler');
  }

  private setIsMobile() {
    if (this.isMobile) return;
    this.isMobile = true;
    this.controlsHandler.updateMobileState(true);
    this.boundsController.updateMobileState(true);
    this.transformStore.actions.setIsMobile(true);
  }

  private getDistance(t1: Touch, t2: Touch): number {
    const dx = t1.clientX - t2.clientX;
    const dy = t1.clientY - t2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  private getCenter(t1: Touch, t2: Touch): Position {
    return {
      x: (t1.clientX + t2.clientX) / 2,
      y: (t1.clientY + t2.clientY) / 2,
    };
  }

  updateBoundsController(newController: BoundsController): void {
    this.boundsController = newController;
    if (this.isMobile) {
      newController.updateMobileState(true);
    }
  }

  private isControlButton(target: EventTarget | null): boolean {
    if (!target) return false;
    const element = target as HTMLElement;
    return element.tagName === 'BUTTON' || element.closest('button') !== null;
  }

  handleTouchStart = (e: TouchEvent): void => {
    this.setIsMobile();
    if (this.isControlButton(e.target)) return;
    e.preventDefault();

    const touches = Array.from(e.touches);

    this.logger.debug('Touch start', {
      touchCount: touches.length,
      timestamp: Date.now(),
    });

    this.transformStore.actions.setDragging(true);

    if (touches.length === 2) {
      const t1 = touches[0]!;
      const t2 = touches[1]!;

      this.gestureState = {
        isMobile: true,
        initialScale: this.transformStore.state.scale,
        initialRotation: 0,
        initialDistance: this.getDistance(t1, t2),
        lastPosition: this.getCenter(t1, t2),
        startTime: Date.now(),
        lastTapTime: 0,
        isMultiTouch: true,
        lastZoomCenter: this.getCenter(t1, t2),
      };

      this.logger.debug('Two-finger gesture started', {
        initialScale: this.gestureState.initialScale,
        initialDistance: this.gestureState.initialDistance,
      });
    } else if (touches.length === 1) {
      const now = Date.now();
      const lastTapTime = this.gestureState?.lastTapTime || 0;

      if (now - lastTapTime < MobileInteractionHandler.DOUBLE_TAP_THRESHOLD) {
        this.handleDoubleTap(touches[0]!);
      } else {
        this.gestureState = {
          isMobile: true,
          initialScale: this.transformStore.state.scale,
          initialRotation: 0,
          initialDistance: 0,
          lastPosition: { x: touches[0]!.clientX, y: touches[0]!.clientY },
          startTime: now,
          lastTapTime: now,
          isMultiTouch: false,
        };

        this.logger.debug('Single-finger gesture started', {
          position: this.gestureState.lastPosition,
          timestamp: now,
        });
      }
    }
  };

  handleTouchMove = (e: TouchEvent): void => {
    if (this.isControlButton(e.target)) return;
    e.preventDefault();
    if (!this.gestureState) return;

    const touches = Array.from(e.touches);

    if (touches.length === 2 && this.gestureState.isMultiTouch) {
      // Handle pinch zoom
      const t1 = touches[0]!;
      const t2 = touches[1]!;
      const currentDistance = this.getDistance(t1, t2);
      const currentCenter = this.getCenter(t1, t2);

      // Calculate scale change
      const scaleDelta = currentDistance / this.gestureState.initialDistance;
      const newScale = Math.min(
        MobileInteractionHandler.MAX_SCALE,
        Math.max(
          MobileInteractionHandler.MIN_SCALE,
          this.gestureState.initialScale * scaleDelta,
        ),
      );

      // Apply position changes without any bounds checking
      if (this.gestureState.lastZoomCenter) {
        const dx = currentCenter.x - this.gestureState.lastZoomCenter.x;
        const dy = currentCenter.y - this.gestureState.lastZoomCenter.y;

        const currentPos = this.transformStore.state.position;
        const newPosition = {
          x: currentPos.x + dx,
          y: currentPos.y + dy,
        };

        // Apply new scale and position directly without bounds
        this.transformStore.actions.setScale(newScale);
        this.transformStore.actions.setPosition(newPosition);
      }

      this.gestureState.lastZoomCenter = currentCenter;
      this.gestureState.initialDistance = currentDistance;
    } else if (touches.length === 1 && !this.gestureState.isMultiTouch) {
      // Handle single finger pan
      const touch = touches[0]!;
      const deltaX = touch.clientX - this.gestureState.lastPosition.x;
      const deltaY = touch.clientY - this.gestureState.lastPosition.y;

      const currentPos = this.transformStore.state.position;
      const newPosition = {
        x: currentPos.x + deltaX,
        y: currentPos.y + deltaY,
      };

      // Apply new position directly without any bounds checking
      this.transformStore.actions.setPosition(newPosition);
      this.gestureState.lastPosition = { x: touch.clientX, y: touch.clientY };
    }
  };

  handleTouchEnd = (e: TouchEvent): void => {
    if (this.isControlButton(e.target)) return;
    e.preventDefault();

    if (e.touches.length === 0) {
      // Keep the final position without applying bounds
      this.transformStore.actions.setDragging(false);
      this.gestureState = null;
    } else if (e.touches.length === 1) {
      // Reset to single touch handling
      const touch = e.touches[0]!;
      this.gestureState = {
        isMobile: true,
        initialScale: this.transformStore.state.scale,
        initialRotation: 0,
        initialDistance: 0,
        lastPosition: { x: touch.clientX, y: touch.clientY },
        startTime: Date.now(),
        lastTapTime: this.gestureState?.lastTapTime ?? 0,
        isMultiTouch: false,
      };
    }
  };

  private handleDoubleTap(touch: Touch): void {
    const currentScale = this.transformStore.state.scale;
    const snappedScale = TransformUtils.snapToNearestZoomLevel(currentScale);
    const targetScale = snappedScale > 1 ? 1 : 2;

    this.animateScale(snappedScale, targetScale, {
      x: touch.clientX,
      y: touch.clientY,
    });
  }

  private animateScale(
    startScale: number,
    endScale: number,
    center: Position,
  ): void {
    const startTime = performance.now();
    const duration = 300;
    const startPos = this.transformStore.state.position;

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      if (progress < 1) {
        const easeProgress = 1 - Math.pow(1 - progress, 3);
        const currentScale =
          startScale + (endScale - startScale) * easeProgress;

        this.transformStore.actions.setScale(currentScale);

        // Adjust position to maintain zoom center
        const scaleRatio = currentScale / startScale;
        const newPos = {
          x: center.x - (center.x - startPos.x) * scaleRatio,
          y: center.y - (center.y - startPos.y) * scaleRatio,
        };

        // Apply position directly without bounds during animation
        this.transformStore.actions.setPosition(newPos);
        requestAnimationFrame(animate);
      } else {
        this.transformStore.actions.setScale(endScale);
      }
    };

    requestAnimationFrame(animate);
  }
}

// import { Logger } from '@repo/logger';
// import { TransformStore } from './TransformStore';
// import { BoundsController } from './BoundsController';
// import { AnimationController } from './AnimationController';
// import { Position } from './types';
// import { TouchGestureState } from './TouchGestureState';
// import { TransformUtils } from './types';

// export class MobileInteractionHandler {
//   private logger: Logger;
//   private static readonly DOUBLE_TAP_THRESHOLD = 300;
//   private static readonly MIN_SCALE = 0.125;
//   private static readonly MAX_SCALE = 2.5;
//   private static readonly MIN_ROTATION_DELTA = 5;
//   private static readonly REFERENCE_WIDTH = 1024; // Base width for scale calculations

//   private gestureState: TouchGestureState | null = null;

//   constructor(
//     private transformStore: TransformStore,
//     private boundsController: BoundsController,
//     private animationController: AnimationController,
//   ) {
//     this.logger = new Logger('MobileInteractionHandler');
//   }

//   private getDistance(t1: Touch, t2: Touch): number {
//     const dx = t1.clientX - t2.clientX;
//     const dy = t1.clientY - t2.clientY;
//     return Math.sqrt(dx * dx + dy * dy);
//   }

//   private getRotation(t1: Touch, t2: Touch): number {
//     return (
//       Math.atan2(t2.clientY - t1.clientY, t2.clientX - t1.clientX) *
//       (180 / Math.PI)
//     );
//   }

//   private getCenter(t1: Touch, t2: Touch): Position {
//     return {
//       x: (t1.clientX + t2.clientX) / 2,
//       y: (t1.clientY + t2.clientY) / 2,
//     };
//   }

//   private calculateRealScale(scale: number, containerWidth: number): number {
//     return (scale * containerWidth) / MobileInteractionHandler.REFERENCE_WIDTH;
//   }

//   updateBoundsController(newController: BoundsController): void {
//     this.boundsController = newController;
//   }

//   handleTouchStart = (e: TouchEvent): void => {
//     e.preventDefault();
//     this.boundsController.setIsMobile(true);
//     const touches = Array.from(e.touches);

//     this.logger.debug('Touch start', {
//       touchCount: touches.length,
//       timestamp: Date.now(),
//     });

//     this.transformStore.actions.setDragging(true);

//     if (touches.length === 2) {
//       const t1 = touches[0]!;
//       const t2 = touches[1]!;

//       this.gestureState = {
//         initialScale: this.transformStore.state.scale,
//         initialRotation: this.getRotation(t1, t2),
//         initialDistance: this.getDistance(t1, t2),
//         lastPosition: this.getCenter(t1, t2),
//         startTime: Date.now(),
//         lastTapTime: 0,
//         isMultiTouch: true,
//         lastZoomCenter: this.getCenter(t1, t2),
//       };

//       this.logger.debug('Two-finger gesture started', {
//         initialScale: this.gestureState.initialScale,
//         initialRotation: this.gestureState.initialRotation,
//         initialDistance: this.gestureState.initialDistance,
//       });
//     } else if (touches.length === 1) {
//       const now = Date.now();
//       const lastTapTime = this.gestureState?.lastTapTime || 0;

//       if (now - lastTapTime < MobileInteractionHandler.DOUBLE_TAP_THRESHOLD) {
//         this.handleDoubleTap(touches[0]!);
//       } else {
//         this.gestureState = {
//           initialScale: this.transformStore.state.scale,
//           initialRotation: this.transformStore.state.rotation,
//           initialDistance: 0,
//           lastPosition: { x: touches[0]!.clientX, y: touches[0]!.clientY },
//           startTime: now,
//           lastTapTime: now,
//           isMultiTouch: false,
//         };

//         this.logger.debug('Single-finger gesture started', {
//           position: this.gestureState.lastPosition,
//           timestamp: now,
//         });
//       }
//     }
//   };

//   handleTouchMove = (e: TouchEvent): void => {
//     e.preventDefault();
//     if (!this.gestureState) return;

//     const touches = Array.from(e.touches);

//     if (touches.length === 2 && this.gestureState.isMultiTouch) {
//       const t1 = touches[0]!;
//       const t2 = touches[1]!;
//       const currentDistance = this.getDistance(t1, t2);
//       const currentCenter = this.getCenter(t1, t2);

//       // Handle scaling
//       const scaleDelta = currentDistance / this.gestureState.initialDistance;
//       const newScale = Math.min(
//         MobileInteractionHandler.MAX_SCALE,
//         Math.max(
//           MobileInteractionHandler.MIN_SCALE,
//           this.gestureState.initialScale * scaleDelta,
//         ),
//       );

//       // Apply position changes without bounds
//       if (this.gestureState.lastZoomCenter) {
//         const dx = currentCenter.x - this.gestureState.lastZoomCenter.x;
//         const dy = currentCenter.y - this.gestureState.lastZoomCenter.y;

//         const currentPos = this.transformStore.state.position;
//         const newPosition = {
//           x: currentPos.x + dx,
//           y: currentPos.y + dy,
//         };

//         this.transformStore.actions.setScale(newScale);
//         this.transformStore.actions.setPosition(newPosition);
//       }

//       this.gestureState.lastZoomCenter = currentCenter;
//       this.gestureState.initialDistance = currentDistance;
//     } else if (touches.length === 1 && !this.gestureState.isMultiTouch) {
//       const touch = touches[0]!;
//       const deltaX = touch.clientX - this.gestureState.lastPosition.x;
//       const deltaY = touch.clientY - this.gestureState.lastPosition.y;

//       const currentPos = this.transformStore.state.position;
//       const newPosition = {
//         x: currentPos.x + deltaX,
//         y: currentPos.y + deltaY,
//       };

//       // Apply position without bounds for single touch
//       this.transformStore.actions.setPosition(newPosition);
//       this.gestureState.lastPosition = { x: touch.clientX, y: touch.clientY };
//     }
//   };

//   handleTouchEnd = (e: TouchEvent): void => {
//     e.preventDefault();

//     if (e.touches.length === 0) {
//       // No bounds check on touch end - allow free positioning
//       this.transformStore.actions.setDragging(false);
//       this.gestureState = null;
//     } else if (e.touches.length === 1) {
//       // Reset to single touch handling
//       const touch = e.touches[0]!;
//       this.gestureState = {
//         initialScale: this.transformStore.state.scale,
//         initialRotation: 0,
//         initialDistance: 0,
//         lastPosition: { x: touch.clientX, y: touch.clientY },
//         startTime: Date.now(),
//         lastTapTime: this.gestureState?.lastTapTime ?? 0,
//         isMultiTouch: false,
//       };
//     }
//   };

//   // handleTouchMove = (e: TouchEvent): void => {
//   //   e.preventDefault();
//   //   if (!this.gestureState) return;

//   //   const touches = Array.from(e.touches);

//   //   if (touches.length === 2 && this.gestureState.isMultiTouch) {
//   //     const t1 = touches[0]!;
//   //     const t2 = touches[1]!;
//   //     const currentDistance = this.getDistance(t1, t2);
//   //     const currentCenter = this.getCenter(t1, t2);

//   //     // Handle scaling
//   //     const scaleDelta = currentDistance / this.gestureState.initialDistance;
//   //     const newScale = Math.min(
//   //       MobileInteractionHandler.MAX_SCALE,
//   //       Math.max(
//   //         MobileInteractionHandler.MIN_SCALE,
//   //         this.gestureState.initialScale * scaleDelta,
//   //       ),
//   //     );

//   //     // Calculate position without bounds during touch
//   //     if (this.gestureState.lastZoomCenter) {
//   //       const dx = currentCenter.x - this.gestureState.lastZoomCenter.x;
//   //       const dy = currentCenter.y - this.gestureState.lastZoomCenter.y;

//   //       const currentPos = this.transformStore.state.position;
//   //       const newPosition = {
//   //         x: currentPos.x + dx,
//   //         y: currentPos.y + dy,
//   //       };

//   //       // Skip bounds during touch movement
//   //       this.transformStore.actions.setScale(newScale);
//   //       this.transformStore.actions.setPosition(newPosition);
//   //     }

//   //     this.gestureState.lastZoomCenter = currentCenter;
//   //     this.gestureState.initialDistance = currentDistance;
//   //   }
//   // };

//   // handleTouchEnd = (e: TouchEvent): void => {
//   //   e.preventDefault();

//   //   if (e.touches.length === 0) {
//   //     // Apply bounds only when all touches end
//   //     const currentPos = this.transformStore.state.position;
//   //     const boundedPosition = this.boundsController.calculateBounds({
//   //       position: currentPos,
//   //       scale: this.transformStore.state.scale,
//   //       isPortrait: this.transformStore.state.isPortrait,
//   //       containerSize: this.boundsController.getDimensions(),
//   //     });

//   //     if (
//   //       boundedPosition.x !== currentPos.x ||
//   //       boundedPosition.y !== currentPos.y
//   //     ) {
//   //       this.animationController.animatePosition(
//   //         currentPos,
//   //         boundedPosition,
//   //         (pos) => {
//   //           this.transformStore.actions.setPosition(pos);
//   //         },
//   //       );
//   //     }

//   //     this.transformStore.actions.setDragging(false);
//   //     this.gestureState = null;
//   //   } else if (e.touches.length === 1) {
//   //     // Reset to single touch handling
//   //     const touch = e.touches[0]!;
//   //     this.gestureState = {
//   //       initialScale: this.transformStore.state.scale,
//   //       initialRotation: 0,
//   //       initialDistance: 0,
//   //       lastPosition: { x: touch.clientX, y: touch.clientY },
//   //       startTime: Date.now(),
//   //       lastTapTime: this.gestureState?.lastTapTime ?? 0,
//   //       isMultiTouch: false,
//   //     };
//   //   }
//   // };

//   private handleDoubleTap(touch: Touch): void {
//     const containerSize = this.boundsController.getDimensions();
//     const currentScale = this.transformStore.state.scale;
//     const snappedScale = TransformUtils.snapToNearestZoomLevel(currentScale);
//     const targetScale = snappedScale > 1 ? 1 : 2;

//     this.animateScale(snappedScale, targetScale, {
//       x: touch.clientX,
//       y: touch.clientY,
//     });
//   }

//   // private handleDoubleTap(touch: Touch): void {
//   //   const containerSize = this.boundsController.getDimensions();
//   //   const currentScale = this.transformStore.state.scale;
//   //   const realScale = this.calculateRealScale(
//   //     currentScale,
//   //     containerSize.width,
//   //   );
//   //   const targetScale = realScale > 1 ? 1 : 2;

//   //   this.logger.debug('Double tap detected', {
//   //     currentScale: realScale,
//   //     targetScale,
//   //     position: { x: touch.clientX, y: touch.clientY },
//   //   });

//   //   this.animateScale(currentScale, targetScale, {
//   //     x: touch.clientX,
//   //     y: touch.clientY,
//   //   });
//   // }

//   private animateScale(
//     startScale: number,
//     endScale: number,
//     center: Position,
//   ): void {
//     const startTime = performance.now();
//     const duration = 300;
//     const startPos = this.transformStore.state.position;

//     this.logger.debug('Starting scale animation', {
//       startScale,
//       endScale,
//       center,
//       startPosition: startPos,
//     });

//     const animate = (currentTime: number) => {
//       const elapsed = currentTime - startTime;
//       const progress = Math.min(elapsed / duration, 1);

//       if (progress < 1) {
//         const easeProgress = 1 - Math.pow(1 - progress, 3);
//         const currentScale =
//           startScale + (endScale - startScale) * easeProgress;

//         this.logger.debug('Scale animation frame', {
//           progress: easeProgress,
//           currentScale,
//         });

//         this.transformStore.actions.setScale(currentScale);

//         // Adjust position to maintain zoom center
//         const scaleRatio = currentScale / startScale;
//         const newPos = {
//           x: center.x - (center.x - startPos.x) * scaleRatio,
//           y: center.y - (center.y - startPos.y) * scaleRatio,
//         };

//         const boundedPos = this.boundsController.calculateBounds({
//           position: newPos,
//           scale: currentScale,
//           isPortrait: this.transformStore.state.isPortrait,
//           containerSize: this.boundsController.getDimensions(),
//         });

//         this.transformStore.actions.setPosition(boundedPos);
//         requestAnimationFrame(animate);
//       } else {
//         this.logger.debug('Scale animation complete', {
//           finalScale: endScale,
//           finalPosition: this.transformStore.state.position,
//         });

//         this.transformStore.actions.setScale(endScale);
//       }
//     };

//     requestAnimationFrame(animate);
//   }
// }
