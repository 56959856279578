import { Logger } from '@repo/logger';
import { TransformStore } from './TransformStore';
import { BoundsController } from './BoundsController';
import { AnimationController } from './AnimationController';
import { ZoomController } from './ZoomController';
import { TransformUtils } from './types';

export class ControlsHandler {
  private logger: Logger;
  private isMobile: boolean;

  constructor(
    private transformStore: TransformStore,
    private boundsController: BoundsController,
    private animationController: AnimationController,
    private zoomController: ZoomController,
  ) {
    this.logger = new Logger('ControlsHandler');
    this.isMobile = false;
  }

  updateMobileState(isMobile: boolean): void {
    this.isMobile = isMobile;
  }

  updateBoundsController(newController: BoundsController): void {
    this.boundsController = newController;
  }

  handleZoom = (): void => {
    const currentScale = this.transformStore.state.scale;
    const snappedScale = TransformUtils.snapToNearestZoomLevel(currentScale);
    const newScale = TransformUtils.getNextZoomLevel(snappedScale);

    this.transformStore.actions.setScale(newScale);

    const currentPos = this.transformStore.state.position;
    const boundedPosition = this.boundsController.calculateBounds({
      isMobile: this.isMobile,
      position: currentPos,
      scale: newScale,
      isPortrait: this.transformStore.state.isPortrait,
      containerSize: this.boundsController.getDimensions(),
    });

    if (
      boundedPosition.x !== currentPos.x ||
      boundedPosition.y !== currentPos.y
    ) {
      this.transformStore.actions.setPosition(boundedPosition);
    }
  };

  handleRotate = (): void => {
    const currentRotation = this.transformStore.state.rotation;
    const snappedRotation =
      TransformUtils.snapToNearestRotation(currentRotation);
    const newRotation = TransformUtils.getNextRotation(snappedRotation);

    this.transformStore.actions.setRotation(newRotation);

    // Reset position and recalculate bounds after rotation
    this.transformStore.actions.setPosition({ x: 0, y: 0 });

    const boundedPosition = this.boundsController.calculateBounds({
      isMobile: this.isMobile,
      position: { x: 0, y: 0 },
      scale: this.transformStore.state.scale,
      isPortrait: !this.transformStore.state.isPortrait,
      containerSize: this.boundsController.getDimensions(),
    });

    this.transformStore.actions.setPosition(boundedPosition);
  };
}
