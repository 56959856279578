import {
  Component,
  createSignal,
  createEffect,
  onCleanup,
  onMount,
} from 'solid-js';
import { createStore } from 'solid-js/store';
import { Logger } from '@repo/logger';
import { FeedbackControls } from './FeedbackControls';
import { Position, Dimensions } from './types';
import { TransformStore, createTransformStore } from './TransformStore';
import { BoundsController } from './BoundsController';
import { AnimationController } from './AnimationController';
import { MobileInteractionHandler } from './MobileInteractionHandler';
import { DesktopInteractionHandler } from './DesktopInteractionHandler';
import { ZoomController } from './ZoomController';
import { ControlsHandler } from './ControlsHandler';

interface ImageState {
  dimensions: Dimensions;
  isLoading: boolean;
  error: Error | null;
  element: HTMLImageElement | null;
}

interface ImagePreviewProps {
  image: HTMLImageElement | string;
  width?: string;
  height?: string;
  background?: string;
  controls?: {
    show?: boolean;
    position?: 'top' | 'bottom';
    backgroundColor?: string;
    color?: string;
  };
  onError?: (error: unknown) => void;
  onLoad?: () => void;
}

export const ImagePreview: Component<ImagePreviewProps> = (props) => {
  const logger = new Logger('ImagePreview');
  let containerRef!: HTMLDivElement;
  let imageRef!: HTMLImageElement;

  // Initialize stores
  const transformStore = createTransformStore();
  const [imageState, setImageState] = createStore<ImageState>({
    dimensions: { width: 0, height: 0 },
    isLoading: true,
    error: null,
    element: null,
  });

  // Initialize controllers with default dimensions
  // We'll recreate the bounds controller when we have both container and image
  const initialDimensions: Dimensions = { width: 0, height: 0 };
  let boundsController = new BoundsController(
    initialDimensions,
    undefined,
    undefined,
  );
  const animationController = new AnimationController();
  const zoomController = new ZoomController();

  const controlsHandler = new ControlsHandler(
    transformStore,
    boundsController,
    animationController,
    zoomController,
  );

  // Initialize interaction handlers
  const mobileHandler = new MobileInteractionHandler(
    transformStore,
    boundsController,
    animationController,
    controlsHandler,
  );

  const desktopHandler = new DesktopInteractionHandler(
    transformStore,
    boundsController,
    animationController,
  );

  // Update controllers when container is available
  const initializeContainer = (element: HTMLDivElement) => {
    containerRef = element;
    const dimensions = {
      width: element.clientWidth,
      height: element.clientHeight,
    };

    // Create new bounds controller with updated container
    boundsController = new BoundsController(
      dimensions,
      element,
      imageRef, // This might be undefined initially
    );

    // Update handlers with new bounds controller
    mobileHandler.updateBoundsController(boundsController);
    desktopHandler.updateBoundsController(boundsController);

    // Set up resize observer
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (!entry) return;

      const newDimensions = {
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      };

      // Create new bounds controller with updated dimensions
      boundsController = new BoundsController(newDimensions, element, imageRef);

      // Update handlers with new bounds controller
      mobileHandler.updateBoundsController(boundsController);
      desktopHandler.updateBoundsController(boundsController);

      // Recalculate bounds after resize
      const boundedPosition = boundsController.calculateBounds({
        isMobile: transformStore.state.isMobile,
        position: transformStore.state.position,
        scale: transformStore.state.scale,
        isPortrait: transformStore.state.isPortrait,
        containerSize: newDimensions,
      });

      transformStore.actions.setPosition(boundedPosition);
    });

    observer.observe(element);
    onCleanup(() => observer.disconnect());
  };

  // Initialize image when it's loaded
  const initializeImage = (element: HTMLImageElement) => {
    imageRef = element;
    if (containerRef) {
      // Create new bounds controller with both container and image
      boundsController = new BoundsController(
        boundsController.getDimensions(),
        containerRef,
        element,
      );

      // Update handlers with new bounds controller
      mobileHandler.updateBoundsController(boundsController);
      desktopHandler.updateBoundsController(boundsController);
    }
  };

  // Image loading logic
  const loadImage = async (source: HTMLImageElement | string) => {
    try {
      setImageState('isLoading', true);
      setImageState('error', null);

      let img: HTMLImageElement;
      if (source instanceof HTMLImageElement) {
        img = source;
      } else {
        img = new Image();
        img.src = source;
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      }

      logger.debug('Image loaded', {
        width: img.naturalWidth,
        height: img.naturalHeight,
      });

      setImageState({
        dimensions: { width: img.naturalWidth, height: img.naturalHeight },
        element: img,
        isLoading: false,
        error: null,
      });

      // Initialize scale based on container and image dimensions
      if (containerRef) {
        const containerDims = boundsController.getDimensions();
        const imageRatio = img.naturalWidth / img.naturalHeight;
        const containerRatio = containerDims.width / containerDims.height;
        const initialScale = Math.min(
          containerDims.width / img.naturalWidth,
          containerDims.height / img.naturalHeight,
        );

        transformStore.actions.setScale(initialScale);
      }

      props.onLoad?.();
    } catch (err) {
      const error =
        err instanceof Error ? err : new Error('Failed to load image');
      setImageState('error', error);
      setImageState('isLoading', false);
      props.onError?.(error);
    }
  };

  // Load image when prop changes
  createEffect(() => {
    loadImage(props.image);
  });

  return (
    <div
      ref={initializeContainer}
      class="relative overflow-hidden"
      style={{
        width: props.width ?? '100%',
        height: props.height ?? '100%',
        background: props.background ?? 'transparent',
      }}
      onMouseDown={desktopHandler.handleMouseDown}
      onMouseMove={desktopHandler.handleMouseMove}
      onMouseUp={desktopHandler.handleMouseUp}
      onMouseLeave={desktopHandler.handleMouseUp}
      onTouchStart={mobileHandler.handleTouchStart}
      onTouchMove={mobileHandler.handleTouchMove}
      onTouchEnd={mobileHandler.handleTouchEnd}
    >
      <div
        class="absolute inset-0 flex items-center justify-center"
        style={{
          'touch-action': 'none',
          'user-select': 'none',
          'z-index': 1, // Ensure this is below the controls
        }}
      >
        <div
          class="transform-gpu will-change-transform"
          style={{
            transform: transformStore.actions.getTransformStyle(),
            transition: transformStore.state.dragging
              ? 'none'
              : 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          {!imageState.isLoading && !imageState.error && imageState.element && (
            <img
              ref={initializeImage}
              src={
                typeof props.image === 'string' ? props.image : props.image.src
              }
              alt="Preview"
              class="select-none max-w-none"
              draggable={false}
              style={{
                width: `${imageState.dimensions.width}px`,
                height: `${imageState.dimensions.height}px`,
                'transform-origin': 'center',
                'object-fit': 'contain',
              }}
            />
          )}
        </div>
      </div>

      {/* Loading State */}
      {imageState.isLoading && (
        <div class="absolute inset-0 flex items-center justify-center">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
        </div>
      )}

      {/* Error State */}
      {imageState.error && (
        <div class="absolute inset-0 flex items-center justify-center text-red-500">
          Failed to load image
        </div>
      )}

      {/* Controls with higher z-index */}
      {props.controls?.show !== false &&
        !imageState.isLoading &&
        !imageState.error && (
          <FeedbackControls
            scale={transformStore.state.scale}
            rotation={transformStore.state.rotation}
            onZoom={controlsHandler.handleZoom}
            onRotate={controlsHandler.handleRotate}
            currentWidth={boundsController.getDimensions().width}
            backgroundColor={props.controls?.backgroundColor}
            color={props.controls?.color}
          />
        )}
    </div>
  );
};
