import { Logger } from '@repo/logger';
import { Position } from './types';

export class AnimationController {
  private readonly BOUNCE_DURATION = 300;
  private logger: Logger;

  constructor() {
    this.logger = new Logger('AnimationController');
  }

  animatePosition(
    startPos: Position,
    endPos: Position,
    onUpdate: (pos: Position) => void,
    onComplete?: () => void,
  ): void {
    const startTime = performance.now();

    this.logger.debug('Starting position animation', {
      startPos,
      endPos,
      duration: this.BOUNCE_DURATION,
    });

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / this.BOUNCE_DURATION, 1);

      if (progress < 1) {
        const easeProgress = 1 - Math.pow(1 - progress, 3);
        const currentPos = {
          x: startPos.x + (endPos.x - startPos.x) * easeProgress,
          y: startPos.y + (endPos.y - startPos.y) * easeProgress,
        };

        this.logger.debug('Animation frame', {
          progress: easeProgress,
          currentPosition: currentPos,
        });

        onUpdate(currentPos);
        requestAnimationFrame(animate);
      } else {
        this.logger.debug('Animation complete', { finalPosition: endPos });
        onUpdate(endPos);
        onComplete?.();
      }
    };

    requestAnimationFrame(animate);
  }
}
