import { Logger } from '@repo/logger';
import { TransformStore } from './TransformStore';
import { BoundsController } from './BoundsController';
import { AnimationController } from './AnimationController';
import { ZoomController } from './ZoomController';
import { Position, TransformUtils } from './types';

export class DesktopInteractionHandler {
  private logger: Logger;
  private lastMousePosition: Position | null = null;

  constructor(
    private transformStore: TransformStore,
    private boundsController: BoundsController,
    private animationController: AnimationController,
  ) {
    this.logger = new Logger('DesktopInteractionHandler');
  }

  updateBoundsController(newController: BoundsController): void {
    this.boundsController = newController;
  }

  handleMouseDown = (e: MouseEvent): void => {
    e.preventDefault();
    this.logger.debug('Mouse down', { x: e.clientX, y: e.clientY });

    this.lastMousePosition = { x: e.clientX, y: e.clientY };
    this.transformStore.actions.setDragging(true);
  };

  handleMouseMove = (e: MouseEvent): void => {
    if (!this.transformStore.state.dragging || !this.lastMousePosition) {
      return;
    }

    const deltaX = e.clientX - this.lastMousePosition.x;
    const deltaY = e.clientY - this.lastMousePosition.y;

    this.logger.debug('Mouse move', {
      deltaX,
      deltaY,
      isPortrait: this.transformStore.state.isPortrait,
    });

    const currentPos = this.transformStore.state.position;
    const newPosition = {
      x: currentPos.x + deltaX,
      y: currentPos.y + deltaY,
    };

    // Apply bounds based on orientation
    const boundedPosition = this.boundsController.calculateBounds({
      isMobile: false,
      position: newPosition,
      scale: this.transformStore.state.scale,
      isPortrait: this.transformStore.state.isPortrait,
      containerSize: this.boundsController.getDimensions(),
    });

    this.transformStore.actions.setPosition(boundedPosition);
    this.lastMousePosition = { x: e.clientX, y: e.clientY };
  };

  handleMouseUp = (): void => {
    if (!this.transformStore.state.dragging) return;

    const currentPos = this.transformStore.state.position;
    const boundedPosition = this.boundsController.calculateBounds({
      isMobile: false,
      position: currentPos,
      scale: this.transformStore.state.scale,
      isPortrait: this.transformStore.state.isPortrait,
      containerSize: this.boundsController.getDimensions(),
    });

    if (
      boundedPosition.x !== currentPos.x ||
      boundedPosition.y !== currentPos.y
    ) {
      this.animationController.animatePosition(
        currentPos,
        boundedPosition,
        (pos) => {
          this.transformStore.actions.setPosition(pos);
        },
      );
    }

    this.lastMousePosition = null;
    this.transformStore.actions.setDragging(false);
  };
}
